.course-path {
    background-color: var(--black);
    position: fixed;
    border-radius: var(--border-md);
    bottom: 10px;
    left: 10px;
    height: 70vh;
    width: 25vw;
    color: var(--white);
    transition: var(--base-tansition);
    z-index: 10;
    text-align: left;
    transform: translateX(calc(-100% - 10px));
}

.course-path.open {
    transform: translateX(0);
}

.course-path::before {
    content: ">";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    width: 25px;
    height: 50px;
    right: -25px;
    background-color: inherit;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
}

.course-path.open::before {
    content: "<";
}

.course-path-container {
    overflow: scroll;
    height: 100%;
    padding: 30px 15px;
}