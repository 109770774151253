* {
  box-sizing: border-box;
}

:root {
  --base-tansition: .25s ease-out;

  /* Colors */
  --black: #000;
  --white: #fff;

  /* Borders */
  --border-sm: 4px;
  --border-md: 8px;
  --border-lg: 16px;
  --border-rounded: 50%;
}

body {
  margin: 0;
  padding: 0;
  background: #1A1B1C;
  color: rgba(238,238,238, 1);
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

pre {
  background-color: white;
  border-radius: 8px;
  padding: 10px 20px;
  overflow: scroll;
}

pre code {
  background-color: transparent;
}


.code-wrapper {
  background: white;
  padding: 10px;
  margin: 15px -10px 15px -25px;
}

code {
  font-size: 12px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: black;
  /* word-break: break-all; */
  background-color: white;
  padding: 4px;
  border-radius: 4px;
}

iframe {
  width: 100%;
  color: #fff;
  border: none;
  background: white;
  height: 100%;
  overflow: scroll;
}

button,
.button {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
button.disable,
.button.disable {
  opacity: 0.5;
}
a {
  color: white;
  text-decoration: none;
  opacity: 1;
  transition: 0.2s;
}

a:hover {
  opacity: 0.7;
}

ul {

}

li {
  margin-bottom: 10px;
}

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid rgba(238,238,238, 0.2);
}

textarea {
  overflow-y: scroll;
  padding-bottom: 50px;
}

/* .App {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 50%;
  overflow: hidden;
  grid-template-areas:
    "steps html css js"
    "result result result result";
} */

/* .App.steps-wide {
  grid-template-areas:
      "steps html css js"
      "steps result result result";
}

.instructions {
  display: flex;
  position: relative;
  grid-area: steps;
  text-align: left;
  padding: 15px 30px 15px 40px;
  overflow: hidden;
}

.instructions-inner {
  overflow: scroll;
  padding-bottom: 60px;
} */

.html {grid-area: html;}
.css {grid-area: css;}
.js {grid-area: js;}

.homepage {
  background-color: #fff;
  color: #000;
  min-height: 100vh;
  padding: 10%;
}

.editor-result {
  grid-area: result;
}

.editor-interface p {
  line-height: 1.5;
}

/* 
 * Headings
 */
h1 {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 12px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

p {
  line-height: 150%;
}

.instructions ul {
  font-size: 16px;
  padding-left: 25px;
}

.instructions li {
  font-size: 16px;
  font-weight: 100;
  margin-top: 0;
}

.arrow-container {
  margin-top: 40px;
  padding: 15px;
  width: 100%;
  background-color: #171819;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(238,238,238, 0.2);
}

.textarea-code {
  /* flex-grow: 1; */
  position: relative;
}

.textarea-title {
  position: absolute;
  color: rgba(238,238,238, 0.8);
  font-size: 14px;
  padding: 10px;
}

textarea {
  font-size: 14px;
  font-weight: 400;
  background-color: inherit;
  color: #fff;
  width: 100%;
  height: 100%;
  border: none;
  border-left: 1px solid rgba(238,238,238, 0.2);
  padding: 35px 10px;
  outline: 0;
  resize: none;
}

.layout-button {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
}

.overflow-scroll {
  overflow: scroll;
}